import * as React from "react"
import { gql } from "@apollo/client"
import { Button, ButtonGroup, Heading, Stack, useDisclosure } from "@chakra-ui/react"
import dayjs from "dayjs"

import type { Quarter } from "lib/graphql"
import { useGetUserTasksQuery } from "lib/graphql"
import { useBetterTranslation } from "lib/hooks/useTranslation"

import { CreateExpenseForm } from "./CreateExpenseForm"
import { CreateInvoiceForm } from "./CreateInvoiceForm"
import { Modal } from "./Modal"
import { NoData } from "./NoData"
import { TasksList } from "./TasksList"

const _ = gql`
  fragment UserTaskEntity on Entity {
    id
    name
    type
    pendingReports(userId: $userId) {
      id
      year
      quarter
      type
      file
    }
    pendingIncomeData {
      id
      year
    }
    hasUpcomingVatDeadline
  }

  fragment UserTasks on User {
    id
    entities {
      ...UserTaskEntity
    }
  }

  query GetUserTasks($userId: String!) {
    me {
      ...UserTasks
    }
  }
`
export type UploadType = "expense" | "invoice"

interface Props {
  userId: string
}

export function UserTasks({ userId }: Props) {
  const bt = useBetterTranslation()

  const { data } = useGetUserTasksQuery({
    variables: { userId: userId },
    skip: !!!userId,
  })
  const entities = data?.me?.entities

  const entitiesWithTasks = entities?.filter(
    (entity) =>
      entity.hasUpcomingVatDeadline ||
      entity.pendingIncomeData.length > 0 ||
      entity.pendingReports.length > 0,
  )

  const modalProps = useDisclosure()
  const [selectedUpload, setSelectedUpload] = React.useState<UploadType | null>(null)
  const [selectedEntity, setSelectedEntity] = React.useState<{ name: string; id: string } | null>(null)

  const handleModalClose = () => {
    modalProps.onClose()
    setSelectedUpload(null)
  }

  const defaultQuarter = `Q${dayjs()
    .startOf("Q")
    .subtract(1, "days")
    .startOf("Q")
    .quarter()
    .toString()}` as Quarter
  const defaultYear: string = dayjs().startOf("Q").subtract(1, "days").startOf("Q").year().toString()

  if (!entitiesWithTasks || entitiesWithTasks.length === 0) return null

  return (
    <Stack w="100%">
      <Stack spacing={4}>
        <Heading as="h2" color="pink.500" size="md">
          {bt({
            en: "We need your help with the following…",
            nl: "We hebben je hulp nodig met het volgende...",
          })}
        </Heading>
        <>
          {entitiesWithTasks.length > 0 ? (
            <TasksList
              entitiesWithTasks={entitiesWithTasks}
              setSelectedEntity={setSelectedEntity}
              onOpen={modalProps.onOpen}
            />
          ) : (
            <NoData>{bt({ en: "No tasks", nl: "Geen taken" })}</NoData>
          )}
        </>
      </Stack>
      <Modal
        {...modalProps}
        onClose={handleModalClose}
        title={
          selectedUpload === "expense"
            ? bt({ en: "Upload expense", nl: "Uitgave uploaden" })
            : selectedUpload === "invoice"
            ? bt({ en: "Upload invoice", nl: "Omzetfactuur uploaden" })
            : bt({ en: "Upload", nl: "Uploaden" })
        }
        size="2xl"
      >
        {selectedUpload === "expense" ? (
          <>
            <CreateExpenseForm
              onClose={handleModalClose}
              selectedEntity={selectedEntity}
              selectedDate={{ year: defaultYear, quarter: defaultQuarter }}
            />
          </>
        ) : selectedUpload === "invoice" ? (
          <>
            <CreateInvoiceForm
              onClose={handleModalClose}
              selectedEntity={selectedEntity}
              selectedDate={{ year: defaultYear, quarter: defaultQuarter }}
            />
          </>
        ) : (
          <>
            <ButtonGroup>
              <Button onClick={() => setSelectedUpload("expense")}>
                {bt({ en: "Expense", nl: "Uitgave" })}
              </Button>
              <Button onClick={() => setSelectedUpload("invoice")}>
                {bt({ en: "Invoice", nl: "Omzetfactuur" })}
              </Button>
            </ButtonGroup>
          </>
        )}
      </Modal>
    </Stack>
  )
}
